<template>
  <component :is="loaded === false ? 'div' : 'div'">
    <b-card-actions
        ref="refreshCard"
        action-back
        action-save
        @back="hRedirect('apps-clients-list')"
        @save="createItem"
        :title="currentItem.name"
        no-body
        disable-loading-on-refresh
        :loaded.snyc="loaded"
    ></b-card-actions>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form @submit.stop.prevent="handleSubmit(createItem)" @reset.prevent="resetForm">
        <b-row>
          <b-col cols="12">
            <b-card>
              <div class="mb-2">
                <b-form-checkbox v-model="currentItem.status" value="1" name="status" class="custom-control-success">
                  {{ $t('Active') }}
                </b-form-checkbox>
              </div>

              <b-row>
                <b-col md="6">
                  <validation-provider #default="validationContext" :name="$t('Client name')" rules="required">
                    <b-form-group :label="$t('Client name')">
                      <b-form-input v-model="currentItem.name" trim :placeholder="$t('Client name')"/>
                      <small class="text-danger">{{ validationContext.errors[0] }}</small>
                      <small v-if="validate && Object.keys(validation).includes('name')" class="text-danger">{{
                          $t(validation.name[0])
                        }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="6">
                  <validation-provider #default="validationContext" :name="$t('Domain')" rules="required">

                    <b-form-group :label="$t('Domain')">
                      <b-form-input v-model="currentItem.subdomain" id="subdomain" :placeholder="$t('Domain')"/>
                      <small class="text-danger">{{ validationContext.errors[0] }}</small>
                      <small v-if="validate && Object.keys(validation).includes('subdomain')" class="text-danger">{{
                          $t(validation.subdomain[0])
                        }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>

              <hr>
              <h4>Fee</h4>
              <b-row>
                <b-col md="6">
                  <validation-provider #default="validationContext" :name="$t('Fee')" rules="required">
                    <b-form-group :label="$t('Fee in percent')">
                      <b-input-group append="%">
                        <b-form-input id="fee" v-model="currentItem.fee" trim :placeholder="$t('Fee in percent')"/>
                      </b-input-group>
                    </b-form-group>
                    <small class="text-danger">{{ validationContext.errors[0] }}</small>
                    <small v-if="validate && Object.keys(validation).includes('fee')" class="text-danger">{{
                        $t(validation.fee[0])
                      }}</small>
                  </validation-provider>
                </b-col>
                <b-col md="6">
                  <b-form-group :label="$t('Affiliate User')">
                    <b-form-select v-model="currentItem.affiliate_user" :label="$t('Affiliate User')"
                                   :options="affiliateUsers" value-field="id" text-field="name"
                    >
                    </b-form-select>
                  </b-form-group>

                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <b-card :title="$t('Custom sidebar styles')" class="h-100">
              <b-form-group :label="$t('Background color')">
                <b-form-input type="color" v-model="currentItem.colors.sidebar.background"/>
              </b-form-group>

              <b-form-group :label="$t('Text color')">
                <b-form-input type="color" v-model="currentItem.colors.sidebar.textcolor"/>
              </b-form-group>

              <b-form-group :label="$t('Nav-Item background color')">
                <b-form-input type="color" v-model="currentItem.colors.sidebar.navitem.background"/>
              </b-form-group>

              <b-form-group :label="$t('Nav-Item text color')">
                <b-form-input type="color" v-model="currentItem.colors.sidebar.navitem.textcolor"/>
              </b-form-group>

              <b-form-group :label="$t('Nav-Item active text color')">
                <b-form-input type="color" v-model="currentItem.colors.sidebar.navitem.active.textcolor"/>
              </b-form-group>

              <b-form-group :label="$t('Nav-Item active background color')">
                <b-form-input type="color" v-model="currentItem.colors.sidebar.navitem.active.background"/>
              </b-form-group>

              <b-form-group :label="$t('Nav-Item hover text color')">
                <b-form-input type="color" v-model="currentItem.colors.sidebar.navitem.hover.textcolor"/>
              </b-form-group>

              <b-form-group :label="$t('Nav-Item hover background color')">
                <b-form-input type="color" v-model="currentItem.colors.sidebar.navitem.hover.background"/>
              </b-form-group>
            </b-card>
          </b-col>
          <b-col md="4">
            <b-card :title="$t('Custom topbar styles')" class="h-100">
              <b-form-group :label="$t('Background color')">
                <b-form-input type="color" v-model="currentItem.colors.topbar.background"/>
              </b-form-group>

              <b-form-group :label="$t('Text color')">
                <b-form-input type="color" v-model="currentItem.colors.topbar.textcolor"/>
              </b-form-group>


              <b-form-group :label="$t('Link color')">
                <b-form-input type="color" v-model="currentItem.colors.topbar.link.color"/>
              </b-form-group>


              <b-form-group :label="$t('Link hover color')">
                <b-form-input type="color" v-model="currentItem.colors.topbar.link.hover.color"/>
              </b-form-group>
            </b-card>
          </b-col>
          <b-col md="4">
            <b-card :title="$t('Custom colors & button styles')" class="h-100">
              <b-form-group :label="$t('Main color')">
                <b-form-input id="primarycolor" type="color" v-model="currentItem.colors.primarycolor"/>
              </b-form-group>

              <b-form-group :label="$t('Secondary Color')">
                <b-form-input id="secondarycolor" type="color" v-model="currentItem.colors.secondarycolor"/>
              </b-form-group>

              <b-form-group :label="$t('Success color')">
                <b-form-input type="color" v-model="currentItem.colors.button.success"/>
              </b-form-group>

              <b-form-group :label="$t('Danger color')">
                <b-form-input type="color" v-model="currentItem.colors.button.danger"/>
              </b-form-group>


              <b-form-group :label="$t('Info color')">
                <b-form-input type="color" v-model="currentItem.colors.button.info"/>
              </b-form-group>


              <b-form-group :label="$t('Warning color')">
                <b-form-input type="color" v-model="currentItem.colors.button.warning"/>
              </b-form-group>
            </b-card>
          </b-col>
        </b-row>
        <b-card class="mt-2">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success" type="submit">
            {{ $t('Add') }}
          </b-button>
        </b-card>
      </b-form>
    </validation-observer>
  </component>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormCheckbox,
  BInputGroupAppend,
  BInputGroup,
  BCol,
  BRow,
  BCard,
  BFormSelect

} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import BCardActions from '@core/components/b-card-actions/BCardActions'

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BInputGroupAppend,
    BButton,
    BFormCheckbox,
    BInputGroup,
    BCol,
    BRow,
    BCard,
    BFormSelect,
    BCardActions,
    // Form Validation
    ValidationProvider,
    ValidationObserver,

  },
  directives: {
    Ripple,
  },

  data() {
    return {
      currentItem: {
        status: 1,
        name: '',
        id: '',
        subdomain: '',
        fee: 0,
        affiliate_user: '',
        colors: {
          primarycolor: '#207776',
          secondarycolor: '#a47e4e',
          topbar: {
            link: {
              hover: {}
            }
          },
          sidebar: {
            background: '#000000',
            textcolor: '#ffffff',
            navitem: {
              textcolor: '#ffffff',
              background: '#000000',
              active: {
                textcolor: '#ffffff',
                background: '#a47e4e'
              },
              hover: {
                textcolor: '#ffffff',
                background: '#a47e4e',
              }
            }
          },
          button: {
            success: '#207776',
            danger: '#d11e21',
            info: '#026666',
            warning: '#a47e4e'
          }
        }
      },
      loaded: true,
      validation: {},
      validate: false,
      affiliateUsers: []
    }
  },

  methods: {
    createItem() {
      this.$refs.refFormObserver.validate()
          .then((success) => {
            this.validation = {}
            this.validate = false
            if (success) {
              this.helperAddItem(`/clients`, this.currentItem, this.$refs.refFormObserver, null, (response) => {
                this.$router.push(
                    {
                      name: 'apps-clients-edit',
                      params: {
                        clientid: response.data.id
                      },
                    },
                    () => setTimeout(() => {
                      this.hToast('success', 'Success', 'Item saved')
                    })
                )
              })
            } else {
              this.loaded = false
              setTimeout(() => {
                this.loaded = true
              })
            }
          })
    },

    getValidationState({
      dirty,
      validated,
      valid = null
    }) {
      return dirty || validated ? valid : null
    },

    getAffiliateUser() {
      this.$http.get('/affiliates')
          .then(response => {
            this.affiliateUsers = response.data.items
          })
    }
  },
  mounted() {
    this.getAffiliateUser()
  },
}
</script>

<style>
</style>
